//theme
export const dark = {
    body: '#202020',
    text:'#fff',
    blue:'#143464',
    green:'#06e2a0',
    bodyRgba:'32,32,32',
    textRgba:'255,255,255',

    grey:'#bebebe',

    fontxs: '0.75em',
    fontsm: '0.875em',
    fontmd: '1em',
    fontlg: '1.25em',
    fontxl: '2em',
    fontxxl: '3em',
    fontxxxl: '5em',
    fontBig: '10em',

    navHeight: '5rem'
}